import React, { Component } from 'react';
import { getQueryString } from '../utils/utils'
import './page.css'
import { withRouter } from 'react-router-dom';

class CitySeller extends Component {
  constructor(props) {
    super(props)
    const provinceId = getQueryString('id')
    const provinceName = getQueryString('name')
    this.state = {
      provinceId,
      provinceName,
      dealerList: [],
    }
  }

  componentDidMount() {
    const { provinceId } = this.state
    window.$.get(`/api/web/dealer/v1/list?provinceId=${provinceId}`).then(res => {
      if (res.code === 200 && res.data) {
        this.setState({ dealerList: res.data })
      }
    })
  }

  toSeller = (dealer) => {
    const { history } = this.props;
    history.push(`/seller?id=${dealer.id}`)
  }

  render() {
    const { provinceName, dealerList } = this.state
    return (
      <div>
        <div className="seller_border">
          <div className="seller_title">{provinceName}经销商</div>
          {dealerList.map(dealer => (
            <div className="dealer-card" onClick={() => this.toSeller(dealer)}>
              <div className="seller_content">
                <div className="company_detail">
                  <div className="company_name">{dealer.name}</div>
                  <div className="company_tel">电话：{dealer.phone}</div>
                  <div className="company_address">地址：{dealer.address}</div>
                </div>
              </div>
              <div className="brand_table">
                {
                  dealer.brandPoList && dealer.brandPoList.map((brand, index) => (
                    <div
                      className={index % 2 === 0 ? 'brand_item' : 'brand_item brand_item_margin'}
                      style={{ backgroundImage: `url(${brand.logo})` }}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.props.history.push(`/seller?id=${dealer.id}&brandId=${brand.id}`)
                      }}
                    ></div>
                  ))
                }
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withRouter(CitySeller)
