import React, { Component } from 'react';
import { getQueryString } from '../utils/utils'
import './page.css'

export default class Seller extends Component {
  constructor (props) {
    super(props)
    this.state = {
      dealer: {},
      brandId: Number(getQueryString('brandId')),
    }
  }

  componentDidMount () {
    const dealerId = getQueryString('id')
    if (dealerId) {
      window.$.get(`/api/web/dealer/v1/info?dealerId=${dealerId}`).then(res => {
        if (res.code === 200 && res.data) {
          this.setState({ dealer: res.data })
        }
      })
    }
  }

  render() {
    const { dealer, brandId } = this.state
    let filterList = dealer.brandList
    if (brandId && filterList) {
      filterList = filterList.filter(brand => brand.id === brandId)
    }
    return (
      <div>
        <div className="seller_border">
          <div className="seller_title">
            {dealer.name}
            <div className="dealer_phone">
              销售服务热线：{dealer.phone}
            </div>
          </div>
          <div style={{ fontSize: '16px', marginTop: '10px', textAlign: window.TUOTIAN_IS_MOBILE_FLAG ? 'left' : 'center' }}>旗下全国授权制造商：</div>
          {filterList && filterList.map((brand, index) => (
            <div className="brand_in_dealer">
              <div className="seller_logo" style={{ backgroundImage: `url(${brand.logo})` }}></div>
              {brand.factoryList && brand.factoryList.map(factory => (
                <div className="seller_content" style={window.TUOTIAN_IS_MOBILE_FLAG ? {} : { textAlign: 'center' }}>
                  <div className="content_title">{factory.name}</div>
                  {factory.brandNumber && <div className="brand_number">授权证书编号&nbsp;&nbsp;&nbsp;&nbsp;No.{factory.brandNumber}</div>}
                  <div className="content_end">
                    <div>地址：{factory.address}</div>
                    <div>联系人：{factory.owner}&nbsp;&nbsp;{factory.phone}</div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
}