import React, { useState } from 'react';
import '../pages/page.css';

const SearchBar = (props) => {
  const [value, setValue] = useState('');
  const search = () => {
    props.search(value);
  }
  const keyPress = (e) => {
    if (e.charCode === 13) {
      props.search(value);
    }
  }
  return (
    <div className="search-bar">
      <input value={value} onChange={(e) => { setValue(e.target.value) }} onKeyPress={keyPress} placeholder="输入名称查询"/>
      <span onClick={search} className="glyphicon glyphicon-search"></span>
    </div>
  )
};

export default SearchBar;