import { createStore } from 'redux';

const initialState = {
  brandExpanded: [],
  brandSearchText: '',
  brandScrollPosition: 0,
  brandCurProvince: {},
  provinceExpanded: [],
  provinceSearchText: '',
  provinceScrollPosition: 0,
}

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_STATE':
      const { payload } = action;
      return { ...state, ...payload };
    default:
      return state;
  }
}

const store = createStore(reducers);

export default store;
