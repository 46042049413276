import React from 'react';
import '../pages/page.css';

const EquipCard = (props) => {
  const { img, text } = props
  return (
    <div className="col-sm-4 col-xs-6 equip-card">
      <img src={img} alt={text}/>
      <p>{text}</p>
    </div>
  )
};

export default EquipCard;