import React, { Component } from 'react';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.min.css';
import './page.css'

export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0
    };
  }
  componentDidMount () {
    const imgList = document.querySelectorAll('.honor-img')
    imgList.forEach(el => {
      new Viewer(el);
    })
  }
  last() {
    let index
    if (this.state.index === 0 ) {
      index = 2;
    } else {
      index = this.state.index - 1;
    }
    this.setState({ index });
  }
  next() {
    let index
    if (this.state.index === 2 ) {
      index = 0;
    } else {
      index = this.state.index + 1;
    }
    this.setState({ index });
  }
  render() {
    return (
      <div className={window.TUOTIAN_IS_MOBILE_FLAG ? 'about mobile' : 'about'}>
        <div className="container">
          <div style={{marginTop:  window.TUOTIAN_IS_MOBILE_FLAG ? '20px' : '120px'}}>
            <p className="about-en-title">About us</p>
            <p className="about-zh-title">托田铝业有限公司</p>
          </div>
          {
            window.TUOTIAN_IS_MOBILE_FLAG ?
              <div className="about-text" style={{marginTop: '20px'}}>
                <div className="about-img" style={{marginTop: '20px'}}>
                  <img src="/images/home_5.jpg" alt="托田铝业"/>
                </div>
                <p>辽宁托田铝业有限公司，成立于2000年，占地面积21万平方米，是集铝合金型材产品研发、生产与销售为一体，并具有自行生产热挤压模具、粉末涂料、隔热条、包装材料等能力的综合型企业。是国内少有的产业链配套齐全的大型企业。</p>
                <p>公司专业研发制造建筑、装饰、工业型材、系统门窗等产品，公司拥有国内先进的生产设备，务实的人才队伍，齐全的产品系列。建立起了集熔铸、挤压、氧化、电泳、粉末喷涂、氟碳喷涂、隔热、木纹转印、晶泳、氟泳、拉丝铜，发泡填充、喷砂、机械拉线等生产工序为一体；并有门窗加工、模具制造、粉末制造、隔热条制造、包装材料制造为配套，形成国内少有的产业配套完整的铝型材生产系统，年产能达到10万吨。公司已获各项专利120多项，产品共有800多个系列，20000多个型号规格，产品畅销国内各省市及远销国外，特别是隔热型材在国内享誉胜名，深受广大客户的支持与信赖。</p>
                <p>公司拥有齐全先进的研发和检测设备，并通过了ISO9001、ISO14001、OHSAS18001、CQC产品节能认证等；公司建立了一支高水平的管理和研发团队，自主研发了各种外观精美，性能优良的产品，表面处理研发创新更是精益求精，公司拥有1500多种颜色供客户选择，并且有很多外观精美性能优越的表面处理技术属国内领先，公司一直以科技创新保持在铝加工行业中的领军地位。</p>
                <p>公司本着“管理德为本，质量精为贵，创新实为重，服务信为道”的质量方针，并通过保质、保量、保时及优质服务，竭诚服务好每一个客户，与客户一起共享发展，共创辉煌。</p>
              </div> : 
              <div className="about-pc" style={{marginTop: '20px'}}>
                <div className="about-pc-top">
                  <div className="about-pc-top-left">
                    <p>辽宁托田铝业有限公司，成立于2000年，占地面积21万平方米，是集铝合金型材产品研发、生产与销售为一体，并具有自行生产热挤压模具、粉末涂料、隔热条、包装材料等能力的综合型企业。是国内少有的产业链配套齐全的大型企业。</p>
                    <p>公司专业研发制造建筑、装饰、工业型材、系统门窗等产品，拥有国内先进的生产设备，务实的人才队伍，齐全的产品系列。建立起了集熔铸、挤压、氧化、电泳、粉末喷涂、氟碳喷涂、隔热、木纹转印、晶泳、氟泳、拉丝铜，发泡填充、喷砂、机械拉线等生产工序为一体；并有门窗加工、模具制造、粉末制造、隔热条制造、包装材料制造为配套，形成国内少有的产业配套完整的铝型材生产系统，年产能达到10万吨。公司已获各项专利120多项，产品共有800多个系列，20000多个型号规格，产品畅销国内各省市及远销国外，特别是隔热型材在国内享誉胜名，深受广大客户的支持与信赖。</p>
                    <p>公司拥有齐全先进的研发和检测设备，并通过了ISO9001、ISO14001、OHSAS18001、CQC产品节能认证等；公司建立了一支高水平的管理和研发团队，自主研发了各种外观精美，性能优良的产品，表面处理研发创新更是精益求精，公司拥有1500多种颜色供客户选择，并且有很多外观精美性能优越的表面处理技术属国内领先，公司一直以科技创新保持在铝加工行业中的领军地位。</p>
                  </div>
                  <div className="about-pc-top-right">
                    <img src="/images/home_5.jpg" alt="托田铝业"/>
                  </div>
                </div>
                <div className="about-pc-bottom">
                  <p>公司本着“管理德为本，质量精为贵，创新实为重，服务信为道”的质量方针，并通过保质、保量、保时及优质服务，竭诚服务好每一个客户，与客户一起共享发展，共创辉煌。</p>
                </div>
              </div>
          }
        </div>
        <div className="container honor">
          {
            this.state.index > 0 ? 
            <span className="button" onClick={this.last.bind(this)}><span className="glyphicon glyphicon-chevron-left"></span></span> : ''
          }
          {
            this.state.index < 2 ? 
            <span className="button right" onClick={this.next.bind(this)}><span className="glyphicon glyphicon-chevron-right"></span></span> : ''
          }
          
          <div className="main-sub-title">
            <div className="main-title">荣誉资质</div>
            <div className="separator"><span></span></div>
            <div className="sub-title">中国断桥铝的摇篮</div>
          </div>
          <div className="slider-container">
            <div className="row honor-img" style={{transform: `translateX(${this.state.index === 0 ? '0' : '-100%'})`}}>
              <div className="col-md-2 col-sm-6 col-xs-6">
                <img src="/images/about_2.jpg" alt="托田铝业"/>
                <p>中国驰名商标-北晨</p>
              </div>
              <div className="col-md-2 col-sm-6 col-xs-6">
                <img src="/images/about_3.jpg" alt="托田铝业"/>
                <p>辽宁省名牌</p>
              </div>
              <div className="col-md-2 col-sm-6 col-xs-6">
                <img src="/images/about_4.jpg" alt="托田铝业"/>
                <p>营口AAA级信誉企业</p>
              </div>
              <div className="col-md-2 col-sm-6 col-xs-6">
                <img src="/images/about_5.jpg" alt="托田铝业"/>
                <p>重合同守信用</p>
              </div>
              <div className="col-md-2 col-sm-6 col-xs-6">
                <img src="/images/about_6.jpg" alt="托田铝业"/>
                <p>质量信得过产品</p>
                <p>2015-2018</p>
              </div>
              <div className="col-md-2 col-sm-6 col-xs-6">
                <img src="/images/about_7.jpg" alt="托田铝业"/>
                <p>全国质量诚信标杆典型企业</p>
              </div>
            </div>
            <div className="row honor-img" style={{transform: `translateX(${this.state.index === 1 ? '0' : this.state.index < 1 ? '100%' : '-100%'})`}}>
              <div className="col-md-2 col-sm-6 col-xs-6">
                <img src="/images/about_8.jpg" alt="托田铝业"/>
                <p>阳极氧化型材</p>
                <p>2019-2022</p>
              </div>
              <div className="col-md-2 col-sm-6 col-xs-6">
                <img src="/images/about_9.jpg" alt="托田铝业"/>
                <p>电泳涂漆型材</p>
                <p>2019-2022</p>
              </div>
              <div className="col-md-2 col-sm-6 col-xs-6">
                <img src="/images/about_111.jpg" alt="托田铝业"/>
                <p>喷粉型材</p>
                <p>2019-2022</p>
              </div>
              <div className="col-md-2 col-sm-6 col-xs-6">
                <img src="/images/about_11.jpg" alt="托田铝业"/>
                <p>隔热型材</p>
                <p>2019-2022</p>
              </div>
              <div className="col-md-2 col-sm-6 col-xs-6">
                <img src="/images/about_12.jpg" alt="托田铝业"/>
                <p>节能认证书(TGP-A63)</p>
              </div>
              <div className="col-md-2 col-sm-6 col-xs-6">
                <img src="/images/about_13.jpg" alt="托田铝业"/>
                <p>节能认证书(TGP-A63s)</p>
              </div>
            </div>
            <div className="row honor-img" style={{transform: `translateX(${this.state.index === 2 ? '0' : this.state.index < 2 ? '100%' : '-100%'})`}}>
              <div className="col-md-2 col-sm-6 col-xs-6">
                <img src="/images/about_14.jpg" alt="托田铝业"/>
                <p>节能认证书(DS80)</p>
              </div>
              <div className="col-md-2 col-sm-6 col-xs-6">
                <img src="/images/about_15.jpg" alt="托田铝业"/>
                <p>节能认证书(TSD65)</p>
              </div>
              <div className="col-md-2 col-sm-6 col-xs-6">
                <img src="/images/about_162.jpg" alt="托田铝业"/>
                <p>ISO19001质量管理体系认证证书</p>
              </div>
              <div className="col-md-2 col-sm-6 col-xs-6">
                <img src="/images/about_172.jpg" alt="托田铝业"/>
                <p>ISO14001环境管理体系认证证书</p>
              </div>
              <div className="col-md-2 col-sm-6 col-xs-6">
                <img src="/images/about_182.jpg" alt="托田铝业"/>
                <p>OHSMS职业健康安全管理体系认证证书</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container culture">
          <div className="main-sub-title">
            <div className="main-title">企业文化</div>
            <div className="separator"><span></span></div>
            <div className="sub-title">中国断桥铝的摇篮</div>
          </div>
          <div className="row culture-text">
            <div className="col-md-4 col-sm-12 col-xs-12" style={{marginTop: window.TUOTIAN_IS_MOBILE_FLAG ? '15px' : '0', textAlign: window.TUOTIAN_IS_MOBILE_FLAG ? 'left' : 'center'}}>
              <p>公司精神：诚信&nbsp;&nbsp;和谐&nbsp;&nbsp;奉献&nbsp;&nbsp;创新&nbsp;&nbsp;</p>
              <p style={{marginTop: '15px'}}>经营理念：立足中国&nbsp;&nbsp;走向世界&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
              <p style={{marginTop: '15px'}}>管理方针：树正气&nbsp;&nbsp;讲规矩&nbsp;&nbsp;负责任</p>
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12" style={{marginTop: window.TUOTIAN_IS_MOBILE_FLAG ? '15px' : '0', textAlign: window.TUOTIAN_IS_MOBILE_FLAG ? 'left' : 'center'}}>
              <p>公司作风：迅速反应&nbsp;&nbsp;立即行动</p>
              <p style={{marginTop: '15px'}}>用人原则：以人为本&nbsp;&nbsp;任人唯贤</p>
              <p style={{marginTop: '15px'}}>管理准则：公正&nbsp;&nbsp;公平&nbsp;&nbsp;公开&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12" style={{marginTop: window.TUOTIAN_IS_MOBILE_FLAG ? '15px' : '0', textAlign: window.TUOTIAN_IS_MOBILE_FLAG ? 'left' : 'right'}}>
              <p>质量方针：管理德为本&nbsp;&nbsp;&nbsp;&nbsp;质量精为贵&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
              <p style={{paddingLeft: '70px'}}>创新实为重&nbsp;&nbsp;&nbsp;&nbsp;服务信为道&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
              <p style={{marginTop: '15px'}}>生产原则：不投入——不合格的原材料不投产&nbsp;&nbsp;&nbsp;&nbsp;</p>
              <p style={{paddingLeft: '70px'}}>不转序——不合格的半成品不转序&nbsp;&nbsp;&nbsp;&nbsp;</p>
              <p style={{paddingLeft: '70px'}}>不入库——不合格的产成品不入库&nbsp;&nbsp;&nbsp;&nbsp;</p>
            </div>
          </div>
          {
            window.TUOTIAN_IS_MOBILE_FLAG ? '' : 
            <div className="row culture-img">
              <div className="col-md-3 col-sm-6 col-xs-12">
                <img src="/images/about_20.jpg" alt="托田铝业"/>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <img src="/images/about_21.jpg" alt="托田铝业"/>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <img src="/images/about_22.jpg" alt="托田铝业"/>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <img src="/images/about_23.jpg" alt="托田铝业"/>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}