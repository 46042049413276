import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Menu from '../components/Menu';
import './page.css'
import { getQueryString } from '../utils/utils'

class ProductList extends Component {
  constructor(props) {
    super(props)
    const typeId = Number(getQueryString('type'))
    this.state = {
      typeList: [],
      typeId,
      activeParent: 0,
      activeChild: 0,
      productList: [],
      currentProduct: null,
      mappingList: [],
      activeOutter: {},
      activeInner: {},
      searchName: '',
    }
  }
  componentDidMount() {
    this.getData();
  }
  render() {
    const list = [], renderList = []
    for (let i = 0; i < this.state.productList.length; i++) {
      list.push(this.state.productList[i])
    }
    list.forEach((product, index) => {
      if (index < 6) {
        renderList.push(<div key={`product-${product.id}`} className="col-sm-4 col-xs-6 product-item-img" onClick={() => this.selectProduct(product)}><img src={product.img} alt={product.name}/><p><span>{product.name}</span></p></div>)
      } else {
        renderList.push(<div key={`product-${product.id}`} className="col-sm-3 col-xs-6" onClick={() => this.selectProduct(product)}><div className="product-item">{product.name}</div></div>)
      }
    })
    const outterList = [], innerList = []
    if (this.state.currentProduct) {
      this.state.outterList.forEach(mapping => {
        outterList.push(<div className="mapping-card" onClick={() => this.selectOutterMapping(mapping)} key={mapping.id}><img src={mapping.img} alt="图片"/><span>{mapping.name}</span></div>)
      })
      this.state.innerList.forEach(mapping => {
        innerList.push(<div className="mapping-card" onClick={() => this.selectInnerMapping(mapping)} key={mapping.id}><img src={mapping.img} alt="图片"/><span>{mapping.name}</span></div>)
      })
    }
    return (
      <div className={window.TUOTIAN_IS_MOBILE_FLAG ? 'product-list mobile' : 'product-list'}>
        <div className="tuotian-bread">
          <div className="container">
            <div className="bread-title">
              <span className="zh-title">产品展示</span>{window.TUOTIAN_IS_MOBILE_FLAG ? '' : 'PRODUCT'}
            </div>
            {
              !window.TUOTIAN_IS_MOBILE_FLAG ?
                <div className="bread-container">
                  <ol className="breadcrumb">
                    <li>首页</li>
                    <li>产品中心</li>
                    {
                      this.state.currentProduct ? 
                      <li>{this.state.currentProduct.name}</li> : ''
                    }
                    
                  </ol>
                </div>
                : 
                this.state.currentProduct ? <div className="go-back" onClick={() => this.setState({currentProduct: null})}>&lt;&lt;返回</div> : ''
            }
          </div>
        </div>
        <div className={window.TUOTIAN_IS_MOBILE_FLAG ? 'container color-card-main mobile' : 'container color-card-main'}>
          <Menu list={this.state.typeList} activeParent={this.state.activeParent} activeChild={this.state.activeChild} chooseType={this.chooseType.bind(this)} childKey="child" search={this.search.bind(this)}/>
          { this.state.currentProduct ?
            <div className={window.TUOTIAN_IS_MOBILE_FLAG ? 'product-info mobile' : 'product-info'}>
              <div className="product-info-name">{this.state.currentProduct.name}</div>
              <div className="product-info-feature">
                <div className="product-info-title">产品特点</div>
                <img src={this.state.currentProduct.featureImg} alt="产品特点"/>
              </div>
              {
                this.state.typeId !== 7
                ?
                <div className="product-info-detail">
                  <div className="product-info-title">产品结构和颜色</div>
                  <div className="product-detail-main">
                    <div className={window.TUOTIAN_IS_MOBILE_FLAG ? 'product-mapping mobile' : 'product-mapping'}>
                      {
                        this.state.activeOutter ?
                        <div className="outter-mapping">
                          <div className="outter-mapping-rendering"><img src={this.state.activeOutter.renderingImg} alt="图片"/></div>
                          <div className="outter-mapping-card">
                            <p>外侧颜色</p>
                            <div className="mapping-card-container">{outterList}</div>
                          </div>
                        </div> : ''
                      }
                      {
                        this.state.activeOutter ?
                        <div className="inner-mapping">
                          <div className="inner-mapping-rendering"><img src={this.state.activeInner.renderingImg} alt="图片"/></div>
                          <div className="inner-mapping-card">
                            <p>内侧颜色</p>
                            <div className="mapping-card-container">{innerList}</div>
                          </div>
                        </div> : ''
                      }
                    </div>
                    <div className="product-rendering">
                      <img src={this.state.currentProduct.renderingImg} alt="产品结构"/>
                    </div>
                  </div>
                </div> : ''
              }
              {
                this.state.typeId !== 7
                ?
                <div className="product-info-xingcai">
                  <div className="product-info-title">窗用型材</div>
                  <img src={this.state.currentProduct.showImg} alt="窗用型材"/>
                </div> : ''
              }
            </div>
            : 
            <div className={window.TUOTIAN_IS_MOBILE_FLAG ? 'color-card-content mobile' : 'color-card-content'}>
              <div className="row">
                {renderList}
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
  chooseType(parentId, childId) {
    const typeId = childId ? childId : parentId;
    this.props.history.push(`/product-list?type=${typeId}`);
    window.$.get('/api/web/product/v1/list', { typeId, status: 10, name: this.state.searchName }, (res) => {
      const data = res.data
      data.sort((a, b) => b.hot - a.hot)
      this.setState({activeParent: parentId, activeChild: childId, productList: data, currentProduct: null})
    })
  }
  selectProduct(product) {
    window.$.get('/api/web/product/mapping/v1/list', { productId: product.id, status: 10 }, (res) => {
      const data = res.data
      const innerList = [], outterList = [], behindCard = [87, 90, 94, 100];
      if (data && data.length) {
        data.forEach(mapping => {
          const obj = {
            id: mapping.id,
            img: mapping.colorCardPo.img,
            renderingImg: mapping.renderingImg,
            name: mapping.colorCardPo.name,
            sort: behindCard.indexOf(mapping.colorCardId)
          }
          if (mapping.type === 1) {
            innerList.push(obj)
          }
          if (mapping.type === 2) {
            outterList.push(obj)
          }
        })
      }
      innerList.sort((a, b) => a.sort - b.sort);
      outterList.sort((a, b) => a.sort - b.sort);
      this.setState({currentProduct: product, innerList, outterList, activeInner: innerList[0], activeOutter: outterList[0]})
    })
  }
  selectOutterMapping(mapping) {
    this.setState({activeOutter: mapping})
  }
  selectInnerMapping(mapping) {
    this.setState({activeInner: mapping})
  }
  search(value) {
    this.setState({ searchName: value })
    this.getData(value);
  }
  getData(name = '') {
    window.$.get('/api/web/product/v1/tree', { status: 10, name }, (res) => {
      const arr = []
      const data = res.data
      let activeParent = 0, activeChild = 0
      data.forEach(item => {
        const obj = { ...item.value }
        if (obj.parentId === 0) {
          arr.push(obj)
          if (obj.id === this.state.typeId) {
            activeParent = obj.id
          } else if (item.child && item.child.length) {
            obj.child = []
            item.child.forEach(child => {
              const newChild = { ...child.value }
              if (newChild.id === this.state.typeId) {
                activeParent = obj.id
                activeChild = newChild.id
              }
              obj.child.push(newChild)
            })
          }
        }
      });
      const typeId = this.state.typeId
      window.$.get('/api/web/product/v1/list', { typeId, status: 10, name }, (res) => {
        const data = res.data
        data.sort((a, b) => b.hot - a.hot)
        this.setState({typeList: arr, activeParent, activeChild, productList: data})
      })
      
    })
  }
}

export default withRouter(ProductList)