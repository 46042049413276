import React, { Component } from 'react';
import './page.css'

export default class Duanqiao extends Component {
  render() {
    return (
      <div className={window.TUOTIAN_IS_MOBILE_FLAG ? 'duanqiao mobile' : 'duanqiao'}>
        <div style={{textAlign: 'center', marginTop: '50px'}}><span className="top-border-title">托田断桥</span></div>
        <div className="separator"><span></span></div>
        <div style={{marginTop: '45px', width: '100%', bakcgroundColor: '#f9f9f9'}}>
          <div className="container product-equip">
            <p>辽宁托田铝业有限公司，是国内较早引进隔热型材生产技术的厂家，历经数十年的技术创新和发展，形成了以隔热型材研发、生产与销售为主体的集成管理模式。产品纯铝锭原料生产，高精度尺寸控制，完美的千种颜色，自产高精度模具，自产高性能粉末，自产多腔隔热条，阻热发泡填充线，自动化复合生产线，智能化管理系统，超大量现货库存，较短的供货周期，形成了独具特色的托田断桥铝，也成为真正的中国断桥铝的摇篮。</p>
          </div>
        </div>
        <div className="container" style={{marginTop: '90px', backgroundColor: '#fff', padding: '30px'}}><img style={{width: '100%'}} src="/images/duanqiao.jpg" alt="托田断桥"/></div>
      </div>
    );
  }
}