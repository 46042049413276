import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './page.css'
import { getQueryString } from '../utils/utils'
import BrandCard from '../components/BrandCard';
import ProvinceCard from '../components/ProvinceCard';

class Door extends Component {
  constructor(props) {
    super(props)
    const tab = getQueryString('tab') || 'brand'
    this.state = {
      activeTab: tab,
      layout: false,
      brandList: [],
      provinceList: [],
      dealerList: [],
      searchText: '',
      filterText: '',
      searchTextProvince: '',
      filterTextProvince: '',
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const { brandSearchText, provinceSearchText } = nextProps;
    const { filterText, filterTextProvince } = prevState;
    const newState = {};
    if (brandSearchText !== filterText) {
      newState.searchText = brandSearchText;
      newState.filterText = brandSearchText;
    }
    if (provinceSearchText !== filterTextProvince) {
      newState.searchTextProvince = provinceSearchText;
      newState.filterTextProvince = provinceSearchText;
    }
    if (Object.keys(newState).length) {
      return newState;
    }
    return null;
  }

  getBrandList = () => {
    window.$.get('/api/web/dealer/v1/brandList').then(res => {
      if (res.code === 200 && res.data) {
        this.setState({brandList: res.data})
      }
    })
  }
  getProvinceList = () => {
    window.$.get('/api/web/dealer/v1/province/list').then(res => {
      if (res.code === 200 && res.data) {
        this.setState({provinceList: res.data})
      }
    })
  }
  getDealerList = () => {
    window.$.get('/api/web/dealer/v1/list').then(res => {
      if (res.code === 200 && res.data) {
        this.setState({dealerList: res.data})
      }
    })
  }
  changeTab = (tab) => {
    const { history } = this.props;
    this.setState({activeTab: tab})
    history.push(`/door?tab=${tab}`)
  }
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.updateFilterText();
    }
  }
  updateFilterText = () => {
    const { searchText } = this.state;
    const { dispatch } = this.props;
    dispatch({ type: 'UPDATE_STATE', payload: { brandSearchText: searchText } });
  }
  handleKeyPressProvince = (event) => {
    if (event.key === 'Enter') {
      this.updateFilterTextProvince();
    }
  }
  updateFilterTextProvince = () => {
    const { searchTextProvince } = this.state;
    const { dispatch } = this.props;
    dispatch({ type: 'UPDATE_STATE', payload: { provinceSearchText: searchTextProvince } });
  }
  buildrovinceData = (provinceList, dealerList) => {
    return provinceList.map(province => {
      const dealers = dealerList.filter(dealer => dealer.provinceId === province.id);
      return {
        ...province,
        dealers,
      }
    })
  }
  filterBrand = (brandList, filterText) => {
    if (!filterText) {
      return brandList;
    }
    const result = [];
    for (const brand of brandList) {
      if (brand.name.includes(filterText)) {
        result.push(brand);
        continue;
      }
      const dealers = brand.dealerList.filter(dealer => dealer.name.includes(filterText));
      if (dealers && dealers.length) {
        const brandCopy = { ...brand, dealerList: dealers }
        result.push(brandCopy)
      }
    }
    return result;
  }
  filterProvince = (provinceData, filterTextProvince) => {
    if (!filterTextProvince) {
      return provinceData;
    }
    const result = [];
    for (const province of provinceData) {
      if (province.name.includes(filterTextProvince)) {
        result.push(province);
        continue;
      }
      const dealers = []
      for (const dealer of province.dealers) {
        if (dealer.name.includes(filterTextProvince)) {
          dealers.push(dealer)
          continue;
        }
        const brands = dealer.brandPoList.filter(brand => brand.name.includes(filterTextProvince));
        if (brands && brands.length) {
          const dealerCopy = { ...dealer, brandPoList: brands }
          dealers.push(dealerCopy)
        }
      }
      if (dealers && dealers.length) {
        const provinceCopy = { ...province, dealers }
        result.push(provinceCopy)
      }
    }
    return result;
  }

  componentDidMount() {
    this.getBrandList();
    this.getProvinceList();
    this.getDealerList();
  }

  render() {
    const { activeTab, brandList, provinceList, dealerList, searchText, filterText, searchTextProvince, filterTextProvince } = this.state;
    const provinceData = this.buildrovinceData(provinceList, dealerList);
    const filteredBrandList = this.filterBrand(brandList, filterText);
    const filteredProvinceData = this.filterProvince(provinceData, filterTextProvince);
    return (
      <div>
        <div className="door title">
          <div className="title_inner">
            <div className={activeTab === 'brand' ? 'changeBlue' : ''} onClick={() => this.changeTab('brand')}>品牌列表</div>
            <div className={activeTab === 'province' ? 'changeBlue' : ''} onClick={() => this.changeTab('province')}>省份列表</div>
          </div>
        </div>
        {
          activeTab === 'brand'
            ? (
              <div className="brand">
                <div className="search">
                  <div className="search_box" style={window.TUOTIAN_IS_MOBILE_FLAG ? {} : { width: '500px' }}>
                    <input
                      value={searchText}
                      type="text"
                      placeholder="   搜一搜 | 品牌 全国指定经销商"
                      onKeyDown={this.handleKeyPress}
                      onChange={(e) => this.setState({ searchText: e.target.value })}
                    />
                  </div>
                  <div onClick={this.updateFilterText}></div>
                </div>
                <div style={window.TUOTIAN_IS_MOBILE_FLAG ? {} : { display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
                  {filteredBrandList.map((item) => <BrandCard key={item.id + filterText} brand={item} provinceList={provinceList} />)}
                </div>
              </div>
            )
            : (
              <div className="brand">
                <div className="search">
                  <div className="search_box" style={window.TUOTIAN_IS_MOBILE_FLAG ? {} : { width: '500px' }}>
                    <input
                      value={searchTextProvince}
                      type="text"
                      placeholder="   搜一搜 | 品牌 全国指定经销商"
                      onKeyDown={this.handleKeyPressProvince}
                      onChange={(e) => this.setState({ searchTextProvince: e.target.value })}
                    />
                  </div>
                  <div onClick={this.updateFilterTextProvince}></div>
                </div>
                <div style={window.TUOTIAN_IS_MOBILE_FLAG ? {} : { display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
                  {filteredProvinceData.map((item) => <ProvinceCard key={item.id} province={item} />)}
                </div>
              </div>
            )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  brandSearchText: state.brandSearchText,
  provinceSearchText: state.provinceSearchText,
});

export default connect(mapStateToProps)(withRouter(Door));
