import React, { useMemo, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const BrandCard = (props) => {
  const { brand, provinceList, history, brandExpanded, brandScrollPosition, brandCurProvince, brandSearchText, dispatch } = props;

  const [afterSearchExpand, setAfterSearchExpand] = useState(false);

  const changeLayout = () => {
    if (afterSearchExpand) {
      setAfterSearchExpand(false);
      return;
    }
    const newBrandExpanded = [...brandExpanded];
    const index = newBrandExpanded.indexOf(brand.id);
    if (index > -1) {
      newBrandExpanded.splice(index, 1);
    } else {
      newBrandExpanded.push(brand.id);
    }
    dispatch({ type: 'UPDATE_STATE', payload: { brandExpanded: newBrandExpanded } });
  }

  const selectProvince = (id) => {
    const newBrandCurProvince = { ...brandCurProvince };
    if (id === curProvince) {
      newBrandCurProvince[brand.id] = '';
    } else {
      newBrandCurProvince[brand.id] = id;
    }
    dispatch({ type: 'UPDATE_STATE', payload: { brandCurProvince: newBrandCurProvince } });
  }

  const layout = useMemo(() => {
    return brandExpanded.includes(brand.id);
  }, [brandExpanded, brand])
  const expand = layout || afterSearchExpand;

  const curProvince = useMemo(() => {
    return brandCurProvince[brand.id];
  }, [brandCurProvince, brand])

  useEffect(() => {
    if (window.TUOTIAN_IS_ANDROID_WECHAT_FLAG) {
      document.body.scrollTop = brandScrollPosition;
    } else {
      document.documentElement.scrollTop = brandScrollPosition;
    }
  }, [brandScrollPosition])

  useEffect(() => {
    if (brandSearchText) {
      setAfterSearchExpand(true);
    } else {
      setAfterSearchExpand(false);
    }
  }, [brandSearchText])

  const toDealer = (dealer, brand) => {
    history.push(`/seller?id=${dealer.id}&brandId=${brand.id}`)
    const scrollTop = document.documentElement.scrollTop  || document.body.scrollTop;
    dispatch({ type: 'UPDATE_STATE', payload: { brandScrollPosition: scrollTop } });
  }

  const provinceOptions = useMemo(() => {
    const provinceIds = brand.dealerList && brand.dealerList.map(dealer => dealer.provinceId);
    return provinceList.filter(province => provinceIds && provinceIds.includes(province.id));
  }, [provinceList, brand])

  const afterFilterDealerList = useMemo(() => {
    if (!curProvince) {
      return brand.dealerList;
    }
    return brand.dealerList && brand.dealerList.filter(dealer => dealer.provinceId === curProvince);
  }, [curProvince, brand])

  return (
    <div className="brand-card" style={window.TUOTIAN_IS_MOBILE_FLAG ? {} : { width: '30%', margin: '0 1%' }}>
      <div className="brand_title">{brand.name}</div>
      <div className="brand_content" onClick={changeLayout}>
        <div className="content_left" style={{ backgroundImage: `url(${brand.logo || '/images/brand.png'})` }}></div>
        <div className={expand? 'content_right_change':'content_right'}></div>
      </div>
      {expand && 
        <div className="company_table">
          <div className="province_choose">
            {
              provinceOptions.map(province =>
                <div
                  className={!curProvince || curProvince === province.id ? 'active' : ''}
                  onClick={() => selectProvince(province.id)}
                >
                  {province.shortName}
                </div>
              )
            }
          </div>
          {
            afterFilterDealerList && afterFilterDealerList.length
              ? afterFilterDealerList.map(dealer => (
                  <div className="company_detail" onClick={() => toDealer(dealer, brand)}>
                    <div className="company_name">{dealer.name}</div>
                    <div className="company_tel">电话：{dealer.phone}</div>
                    <div className="company_address">地址：{dealer.address}</div>
                  </div>
                ))
              : '无全国指定经销商'
          }
        </div>
        }
    </div>
  )
}

const mapStateToProps = state => ({
  brandExpanded: state.brandExpanded,
  brandScrollPosition: state.brandScrollPosition,
  brandCurProvince: state.brandCurProvince,
  brandSearchText: state.brandSearchText,
});

export default connect(mapStateToProps)(withRouter(BrandCard));
