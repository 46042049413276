import React, { Component } from 'react';
import SearchBar from './SearchBar';
import './menu.css';

export default class Menu extends Component {
  constructor(props) {
    super(props)
    const { list, activeParent, activeChild, childKey = 'child' } = props
    this.state = {
      list,
      activeParent,
      activeChild,
      childKey,
      fromProps: false
    }
  }
  clickChild(parent, child) {
    this.props.chooseType(parent.id, child.id)
  }
  clickParent(parent) {
    if (!parent[this.state.childKey] || !parent[this.state.childKey].length) {
      this.props.chooseType(parent.id, 0)
    } else {
      if (this.state.activeParent !== parent.id) {
        this.setState({activeParent: parent.id, activeChild: 0, fromProps: false})
      } else {
        this.setState({activeParent: parent.id, activeChild: this.state.activeChild, fromProps: !this.state.fromProps})
      }
    }
  }
  componentWillReceiveProps(props) {
    const { list, activeParent, activeChild } = props
    this.setState({ list, activeParent, activeChild, fromProps: true })
  }

  render() {
    const parentList = []
    this.state.list.forEach((parent, pIndex) => {
      let d = <div className="menu-parent" key={`parent-${pIndex}`} onClick={() => this.clickParent(parent)}>{parent.name}</div>
      if (window.TUOTIAN_IS_MOBILE_FLAG && this.state.childKey === 'colorCardTypeChild') {
        if (this.state.activeParent === parent.id) {
          d = <div className="menu-parent" key={`parent-${pIndex}`} onClick={() => this.clickParent(parent)}>{parent.name}<span>v</span></div>
        } else {
          d = <div className="menu-parent" key={`parent-${pIndex}`} onClick={() => this.clickParent(parent)}>{parent.name}<span>&gt;</span></div>
        }
      }
      const arr = [d]
      if (parent[this.state.childKey]) {
        parent[this.state.childKey].forEach((child, cIndex) => {
          let activeSpan = ''
          if (child.id === this.state.activeChild) {
            activeSpan = <span>&gt;</span>
          }
          arr.push(<div className={window.TUOTIAN_IS_MOBILE_FLAG && this.state.fromProps ? 'menu-child hide' : 'menu-child'} onClick={() => this.clickChild(parent, child)} key={`child-${cIndex}`}>{child.name}{activeSpan}</div>)
        })
      }
      parentList.push(<div className={this.state.activeParent === parent.id ? 'menu-group tuotian-expand' : 'menu-group'} key={`group-${pIndex}`}>{arr}</div>)
    })
    if (window.TUOTIAN_IS_MOBILE_FLAG && this.state.childKey === 'colorCardTypeChild') {
      parentList.unshift(<div className="menu-title">色卡中心</div>)
    }
    return (
      <div className={window.TUOTIAN_IS_MOBILE_FLAG ? 'menu mobile' : 'menu'}>
        <SearchBar search={this.props.search}></SearchBar>
        {parentList}
      </div>
    )
  }
};
