import React, { useMemo, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const ProvinceCard = (props) => {
  const { province, history, dispatch, provinceExpanded, provinceScrollPosition, provinceSearchText } = props;

  const [afterSearchExpand, setAfterSearchExpand] = useState(false);

  const changeLayout = () => {
    if (afterSearchExpand) {
      setAfterSearchExpand(false);
      return;
    }
    const newProvinceExpanded = [...provinceExpanded];
    const index = newProvinceExpanded.indexOf(province.id);
    if (index > -1) {
      newProvinceExpanded.splice(index, 1);
    } else {
      newProvinceExpanded.push(province.id);
    }
    dispatch({ type: 'UPDATE_STATE', payload: { provinceExpanded: newProvinceExpanded } });
  }

  const layout = useMemo(() => {
    return provinceExpanded.includes(province.id);
  }, [provinceExpanded, province])
  const expand = layout || afterSearchExpand;

  const updateScrollTop = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    dispatch({ type: 'UPDATE_STATE', payload: { provinceScrollPosition: scrollTop } });
  }

  useEffect(() => {
    if (window.TUOTIAN_IS_ANDROID_WECHAT_FLAG) {
      document.body.scrollTop = provinceScrollPosition;
    } else {
      document.documentElement.scrollTop = provinceScrollPosition;
    }
  }, [provinceScrollPosition])

  useEffect(() => {
    if (provinceSearchText) {
      setAfterSearchExpand(true);
    } else {
      setAfterSearchExpand(false);
    }
  }, [provinceSearchText])

  return (
    <div className="brand-card" style={window.TUOTIAN_IS_MOBILE_FLAG ? {} : { width: '30%', margin: '0 1%' }}>
      <div className="brand_content" onClick={changeLayout}>
        <div className="content_left">{province.name}</div>
        <div className={expand? 'content_right_change':'content_right'}></div>
      </div>
      {expand && 
        <div className="company_table">
          {province.dealers && province.dealers.map(dealer => (
            <div className="dealer-card" onClick={() => { updateScrollTop(); history.push(`/seller?id=${dealer.id}`) }}>
              <div className="seller_content">
                <div className="company_detail">
                  <div className="company_name">{dealer.name}</div>
                  <div className="company_tel">电话：{dealer.phone}</div>
                  <div className="company_address">地址：{dealer.address}</div>
                </div>
              </div>
              <div className="brand_table">
                {
                  dealer.brandPoList && dealer.brandPoList.map((brand, index) => (
                    <div
                      className={index % 2 === 0 ? 'brand_item' : 'brand_item brand_item_margin'}
                      style={{ backgroundImage: `url(${brand.logo})` }}
                      onClick={(e) => {
                        e.stopPropagation();
                        updateScrollTop();
                        history.push(`/seller?id=${dealer.id}&brandId=${brand.id}`)
                      }}
                    ></div>
                  ))
                }
              </div>
            </div>
          ))}
        </div>
        }
    </div>
  )
}

const mapStateToProps = state => ({
  provinceExpanded: state.provinceExpanded,
  provinceScrollPosition: state.provinceScrollPosition,
  provinceSearchText: state.provinceSearchText,
});

export default connect(mapStateToProps)(withRouter(ProvinceCard));
