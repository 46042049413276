import React from 'react';
import './pager.css';

const Pager = (props) => {
  const { page, pageSize, total } = props
  const pageCount = Math.ceil(total / pageSize)
  const choose = (newPage) => {
    if (newPage < 1) {
      newPage = 1
    }
    if (newPage > pageCount) {
      newPage = pageCount
    }
    if (newPage === page) {
      return
    }
    props.choosePage(newPage)
  }
  const pages = []
  for (let i = 0; i < pageCount; i++) {
    pages.push(<span key={i + 1} className={i + 1 === page ? 'active' : ''} onClick={() => choose(i + 1)}>{i + 1}</span>)
  }
  return (
    <div className="tuotian-pager">
      {
        !window.TUOTIAN_IS_MOBILE_FLAG ?
        <span onClick={() => choose(1)}>首页</span> : ''
      }
      <span onClick={() => choose(page - 1)}>{window.TUOTIAN_IS_MOBILE_FLAG ? '<' : '上一页'}</span>
      {pages}
      <span onClick={() => choose(page + 1)}>{window.TUOTIAN_IS_MOBILE_FLAG ? '>' : '下一页'}</span>
      {
        !window.TUOTIAN_IS_MOBILE_FLAG ?
        <span onClick={() => choose(pageCount)}>尾页</span> : ''
      }
    </div>
  )
};

export default Pager;