import React, { Component } from 'react';
import EquipCard from '../components/EquipCard';
import './page.css'

export default class Equip extends Component {
  render() {
    return (
      <div className={window.TUOTIAN_IS_MOBILE_FLAG ? 'equip mobile' : 'equip'}>
        <div style={{textAlign: 'center', marginTop: '45px'}}><span className="top-border-title">工艺流程</span></div>
        <div className="separator"><span></span></div>
        <div className="container" style={{marginTop: '70px'}}><img style={{width: '100%'}} src="/images/equip_1.png" alt="工艺流程"/></div>
        <div style={{textAlign: 'center', marginTop: '150px'}}><span className="top-border-title">生产装备</span></div>
        <div className="separator"><span></span></div>
        <div style={{marginTop: '45px', width: '100%', bakcgroundColor: '#f9f9f9'}}>
          <div className="container product-equip">
            <p>辽宁托田铝业公司拥有国内先进的生产设备，建立起了集熔铸、挤压、阳极氧化、电泳涂漆、粉末喷涂、氟碳喷涂、隔热型材、木纹转印、晶泳型材、氟泳型材、真铜色型材、发泡填充、喷砂、机械拉丝、抛光等生产工序为一体；并拥有模具制造、粉末制造、隔热条制造、包装材料制造、系统门窗加工为配套，形成国内少有的产业链配套完整的铝型材生产体系</p>
          </div>
        </div>
        <div className="container" style={{marginTop: '25px'}}>
          <div className="row">
            <EquipCard img="/images/equip_2.jpg" text="托田厂区"/>
            <EquipCard img="/images/equip_3.jpg" text="原材料"/>
            <EquipCard img="/images/equip_4.jpg" text="熔铸车间"/>
            <EquipCard img="/images/equip_5.jpg" text="模具制作车间"/>
            <EquipCard img="/images/equip_6.jpg" text="挤压车间"/>
            <EquipCard img="/images/equip_7.jpg" text="喷砂/拉线/抛光车间"/>
            <EquipCard img="/images/equip_8.jpg" text="氧化电泳车间"/>
            <EquipCard img="/images/equip_9.jpg" text="粉末制造车间"/>
            <EquipCard img="/images/equip_10.jpg" text="喷涂车间"/>
            <EquipCard img="/images/equip_11.jpg" text="氟碳车间"/>
            <EquipCard img="/images/equip_12.jpg" text="木纹转印车间"/>
            <EquipCard img="/images/equip_13.jpg" text="隔热条制造车间"/>
            <EquipCard img="/images/equip_14.jpg" text="复合车间"/>
            <EquipCard img="/images/equip_15.jpg" text="发泡填充车间"/>
            <EquipCard img="/images/equip_16.jpg" text="公司成品库"/>
          </div>
        </div>
        <div style={{textAlign: 'center', marginTop: '100px'}}><span className="top-border-title">科研设备</span></div>
        <div className="separator"><span></span></div>
        <div style={{marginTop: '45px', width: '100%', bakcgroundColor: '#f9f9f9'}}>
          <div className="container product-equip">
            <p>托田领导人深知产品要想在市场具有卓越的核心竞争力，就必须具备超出同行业的创新能力。公司不断投入了大量的研发和检测资源，以提升自主研发和创新的能力。公司已获各项专利120多项，产品共有800多个系列，20000多个型号规格。表面处理研发和创新更是精益求精，公司拥有1500多种颜色供客户选择，并且有很多外观精美性能优越的表面处理技术处于国内领先水平。</p>
          </div>
        </div>
        <div className="container" style={{marginTop: '25px'}}>
          <div className="row">
            <EquipCard img="/images/equip_17.jpg" text="直读光谱室"/>
            <EquipCard img="/images/equip_18.jpg" text="力学实验室"/>
            <EquipCard img="/images/equip_19.jpg" text="化学分析室"/>
            <EquipCard img="/images/equip_20.jpg" text="物理分析室"/>
            <EquipCard img="/images/equip_21.jpg" text="盐雾实验室"/>
            <EquipCard img="/images/equip_22.jpg" text="耐候实验室"/>
          </div>
        </div>
      </div>
    );
  }
}