import React from 'react';
import { Provider } from 'react-redux';
import './App.css';
import store from './store';
import { isAndroid, isMobile, isWechat } from './utils/utils';
import { BrowserRouter, NavLink, Route } from 'react-router-dom';
import Home from './pages/home';
import ContactUs from './pages/contact';
import About from './pages/about';
import Product from './pages/product';
import Equip from './pages/equip';
import Duanqiao from './pages/duanqiao';
import ColorCard from './pages/color-card';
import ProductList from './pages/product-list';
import Case from './pages/case';
import Notice from './pages/notice';
import Door from './pages/door';
import Seller from './pages/seller';
import CitySeller from './pages/city-seller';
const App = (language) => {
  const homeUrl = language === 'en' ? 'http://en.tuotianlvye.com' : 'http://www.tuotianlvye.com'
  const thisYear = new Date().getFullYear()
  const isMobileFlag = isMobile(window.navigator)
  const isAndroidWechat = isAndroid(window.navigator) && isWechat(window.navigator)
  window.TUOTIAN_IS_MOBILE_FLAG = isMobileFlag
  window.TUOTIAN_IS_ANDROID_WECHAT_FLAG = isAndroidWechat
  return ( 
    <BrowserRouter>
      <Provider store={store}>
        <div className="App">
          <header>
            {/* <div className="intl">
              <div className="container">
                <a href={language === 'en' ? 'http://www.tuotianlvye.com' : 'http://en.tuotianlvye.com'}>{language === 'en' ? '中文' : 'EN' }</a>
              </div>
            </div> */}
            <div className="container nav">
              <div className="navbar-header">
                {/* <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button> */}
                <a className="navbar-brand" href={homeUrl} style={{marginLeft: window.TUOTIAN_IS_MOBILE_FLAG ? '20px' : '0'}}>
                  <img src="./images/top_logo.png" alt="托田铝业"/>
                </a>
              </div>
              <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                <ul className="nav navbar-nav">
                  <li><NavLink exact to="/" activeStyle={{ color: '#2c527a', textDecoration: 'none' }}>首页 <span className="sr-only">(current)</span></NavLink></li>
                  <li><NavLink exact to="/duanqiao" activeStyle={{ color: '#2c527a', textDecoration: 'none' }}>托田断桥</NavLink></li>
                  <li><NavLink exact to="/door" activeStyle={{ color: '#2c527a', textDecoration: 'none' }}>托田门窗</NavLink></li>
                  <li><NavLink to="/product" activeStyle={{ color: '#2c527a', textDecoration: 'none' }}>产品中心</NavLink></li>
                  {/*  */}
                  {/* <li><NavLink to="/about" activeStyle={{ color: '#2c527a', textDecoration: 'none' }}>产品中心</NavLink></li>
                  
                  <li><NavLink to="/about" activeStyle={{ color: '#2c527a', textDecoration: 'none' }}>新闻资讯</NavLink></li> */}
                  <li><NavLink to="/about" activeStyle={{ color: '#2c527a', textDecoration: 'none' }}>关于托田</NavLink></li>
                  <li><NavLink to="/notice" activeStyle={{ color: '#2c527a', textDecoration: 'none' }}>公告通知</NavLink></li>
                  <li><NavLink to="/equip" activeStyle={{ color: '#2c527a', textDecoration: 'none' }}>技术装备</NavLink></li>
                  <li><NavLink to="/contactus" activeStyle={{ color: '#2c527a', textDecoration: 'none' }}>联系我们</NavLink></li>
                </ul>
              </div>
            </div>
            <div className="container-fluid carousel-container">
              <div id="carousel-example-generic" className="carousel slide">
                { !isMobileFlag ? 
                  <ol className="carousel-indicators">
                    <li data-target="#carousel-example-generic" data-slide-to="0" className="active"></li>
                    <li data-target="#carousel-example-generic" data-slide-to="1"></li>
                    <li data-target="#carousel-example-generic" data-slide-to="2"></li>
                    {/* <li data-target="#carousel-example-generic" data-slide-to="3"></li> */}
                    {/* <li data-target="#carousel-example-generic" data-slide-to="4"></li> */}
                    {/* <li data-target="#carousel-example-generic" data-slide-to="4"></li> */}
                    {/* <li data-target="#carousel-example-generic" data-slide-to="5"></li> */}
                    {/* <li data-target="#carousel-example-generic" data-slide-to="6"></li> */}
                  </ol> : ''
                }
                <div className={window.TUOTIAN_IS_MOBILE_FLAG ? "carousel-inner mobile-carousel" : "carousel-inner"} role="listbox">
                  <div className="item active">
                    <img src="./images/carousel1.jpg" alt="图片"/>
                  </div>
                  <div className="item">
                    <img src="./images/carousel2.jpg" alt="图片"/>
                  </div>
                  {/* <div className="item">
                    <img src="./images/carousel3.jpg" alt="图片"/>
                  </div> */}
                  <div className="item">
                    <img src="./images/carousel4.jpg" alt="图片"/>
                  </div>
                  {/* <div className="item">
                    <img src="./images/carousel5.jpg" alt="图片"/>
                  </div> */}
                  {/* <div className="item">
                    <img src="./images/carousel6.jpg" alt="图片"/>
                  </div>
                  <div className="item">
                    <img src="./images/carousel7.jpg" alt="图片"/>
                  </div>
                  <div className="item">
                    <img src="./images/carousel8.jpg" alt="图片"/>
                  </div> */}
                </div>
                <a className="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
                  <span className="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a className="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
                  <span className="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
            </div>
            {/* <div className={window.TUOTIAN_IS_MOBILE_FLAG ? "sub-title-visible" : "sub-title-invisible"}>
              123
            </div> */}
            <div style={{display:window.TUOTIAN_IS_MOBILE_FLAG?'block':'none'}} className="add-guide">
              <ul>
                <li><NavLink to="/">首页</NavLink></li>
                <li><NavLink to="/duanqiao">托田断桥</NavLink></li>
                <li><NavLink to="/door">托田门窗</NavLink></li>
                <li><NavLink to="/product">产品中心</NavLink></li>
              </ul>
              <ul>
                <li><NavLink to="/about">关于托田</NavLink></li>
                <li><NavLink to="/notice">公告通知</NavLink></li>
                <li><NavLink to="/equip">技术装备</NavLink></li>
                <li><NavLink to="/contactus">联系我们</NavLink></li>
              </ul>
            </div>
          </header>
          <div className="main">
            <Route exact path="/" component={Home} />
            <Route exact path="/product" component={Product} />
            <Route exact path="/contactus" component={ContactUs} />
            <Route exact path="/about" component={About} />
            <Route exact path="/equip" component={Equip} />
            <Route exact path="/duanqiao" component={Duanqiao} />
            <Route exact path="/colorcard" component={ColorCard} />
            <Route exact path="/product-list" component={ProductList} />
            <Route exact path="/case" component={Case} />
            <Route exact path="/notice" component={Notice} />
            <Route exact path="/door" component={Door}/>
            <Route exact path="/seller" component={Seller}/>
            <Route exact path="/city-seller" component={CitySeller}/>
            {/* 
            <Route exact path="/news" component={News} />
            <Route exact path="/article" component={Article} /> */}
          </div>
          <footer>
            { !window.TUOTIAN_IS_MOBILE_FLAG ? <div className="footer-nav">
              <div className="container">
                <ul className="nav navbar-nav">
                  <li className="bottom_logo"><img src="./images/bottom_logo.png" alt="托田铝业"/></li>
                  <li><NavLink exact to="/" activeStyle={{ color: '#fff', textDecoration: 'none' }}>首页<span className="sr-only">(current)</span></NavLink></li>
                  <li><NavLink exact to="/duanqiao" activeStyle={{ color: '#fff', textDecoration: 'none' }}>托田断桥</NavLink></li>
                  <li><NavLink exact to="/product" activeStyle={{ color: '#fff', textDecoration: 'none' }}>产品中心<span className="sr-only">(current)</span></NavLink></li>
                  {/*  */}
                  {/* <li><NavLink to="/about" activeStyle={{ color: '#fff', textDecoration: 'none' }}>产品中心</NavLink></li>
                  <li><NavLink to="/about" activeStyle={{ color: '#fff', textDecoration: 'none' }}>技术装备</NavLink></li>
                  <li><NavLink to="/about" activeStyle={{ color: '#fff', textDecoration: 'none' }}>新闻资讯</NavLink></li> */}
                  <li><NavLink to="/about" activeStyle={{ color: '#fff', textDecoration: 'none' }}>关于托田</NavLink></li>
                  <li><NavLink to="/notice" activeStyle={{ color: '#fff', textDecoration: 'none' }}>公告通知</NavLink></li>
                  <li><NavLink to="/equip" activeStyle={{ color: '#fff', textDecoration: 'none' }}>技术装备</NavLink></li>
                  <li><NavLink to="/contactus" activeStyle={{ color: '#fff', textDecoration: 'none' }}>联系我们</NavLink></li>
                </ul>
              </div>
            </div> : ''}
            <div className="footer-copyright" style={{marginTop: window.TUOTIAN_IS_MOBILE_FLAG ? '50px' : '0'}}>
              Copyright &copy; {thisYear} 托田铝业
            </div>
          </footer>
        </div>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
