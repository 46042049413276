import React, { Component } from 'react';
import './page.css'

export default class Contact extends Component {
  render() {
    return (
      <div className={window.TUOTIAN_IS_MOBILE_FLAG ? 'contact-us mobile' : 'contact-us'}>
        <div className="tuotian-bread">
          <div className="container">
            <div className="bread-title">
              <span className="zh-title">联系我们</span>{window.TUOTIAN_IS_MOBILE_FLAG ? '' : 'CONTACT US'}
            </div>
            {
              !window.TUOTIAN_IS_MOBILE_FLAG ?
                <div className="bread-container">
                  <ol className="breadcrumb">
                    <li>首页</li>
                    <li>联系我们</li>
                  </ol>
                </div> : ''
            }
          </div>
        </div>
        <div className="container contact-way">
          <div style={{textAlign: 'center'}}><span className="top-border-title">联系方式</span></div>
          <div className="row" style={{marginTop: window.TUOTIAN_IS_MOBILE_FLAG ? '0' : '50px'}}>
            <div className="col-md-2 col-sm-12 col-xs-12"></div>
            <div className="col-md-4 col-sm-12 col-xs-12 contact-way-text">
              <div style={{margin: window.TUOTIAN_IS_MOBILE_FLAG ? '0 auto' : '20px auto 0 auto', width: '332px'}}>
                <p>辽宁托田铝业有限公司</p>
                <p>销售服务热线</p>
                <p>400-0892789</p>
                <p style={{marginBottom: '20px'}}>邮编：115113</p>

                <p>地址：辽宁省大石桥市旗口镇红东村</p>
                <p>电话：0417-5062111&nbsp;&nbsp;&nbsp;&nbsp;0417-6956118</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12 contact-way-img">
              <a target="_blank" rel="noopener noreferrer" href="https://www.amap.com/search?id=B01A1143XY&city=210882&geoobj=116.35508%7C40.063448%7C116.367954%7C40.068916&query_type=IDQ&query=辽宁托田铝业有限公司"><img src="/images/contact_map.jpg" alt="托田断桥"/></a>
            </div>
            <div className="col-md-2 col-sm-12 col-xs-12"></div>
          </div>
        </div>
        <div className="container server">
          <div style={{textAlign: 'center'}}><span className="top-border-title">服务承诺</span></div>
          <div className="server-text">
            <p style={{marginBottom: '20px'}}>托田铝业建厂时起，就按ISO9001质量管理体系标准，建立了严格且完善的产品质量保证体系。并全面采用铝材行业ERP管理系统。各工序建立了自检体系、互检体系、专检体系、巡检监查体系、最终产品检验体系，对产品质量实行全面质量管理，保证出厂的产品无质量问题。公司产品售后服务工作由销售员直接负责，并成立专业的技术服务团队提供相应的技术支持和服务。</p>
            <p>一、服务承诺：</p>
            <p>1.	保质、保量、保时交货，产品质量按照GB/T 5237.1～6-2017标准，或行业标准、企业标准的要求执行生产，对每批出厂产品出具质量证明书。</p>
            <p>2.	在使用过程中如出现一些技术问题，公司在接到通知后，将在第一时间给予回复，若需要专业人员到现场协作的，公司都将积极配合，专业指导，细致解决。</p>
            <p style={{marginBottom: '20px'}}>3.	若出现质量异议顾客投诉现象，公司将本着顾客至上的原则，及时沟通，查明原因，妥善处理。</p>
            <p>二、服务措施：</p>
            <p>1.	销售人员对所售出的产品都有进行售后服务的义务，公司的产品售后部门制定并执行相应的补救措施。</p>
            <p>2.	销售人员及时将顾客的投诉意见反馈到公司，由公司总经理签批后转发至相应的生产责任部门。生产责任部门及时制定解决方案和服务措施。由技术质量部负责检查与监督，根据方案和措施及时实施售后服务工作</p>
            <p>3.	及时了解和收集顾客反馈的产品信息，根据顾客的意见，查明情况，分析原因，提出相应的解决方案，在保证满足顾客要求前提下，快速解决相关问题。</p>
            <p>4.	如属公司产品质量问题造成的损坏，公司负责免费更换；若因其它原因造成的产品质量问题，则由双方协商解决。</p>
          </div>
        </div>
      </div>
    );
  }
}