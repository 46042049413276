import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Menu from '../components/Menu';
import Pager from '../components/Pager';
import './page.css'
import { getQueryString } from '../utils/utils'
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.min.css';

class ColorCard extends Component {
  constructor(props) {
    super(props)
    const typeId = Number(getQueryString('type'))
    this.state = {
      typeList: [],
      typeId,
      activeParent: 0,
      activeChild: 0,
      cardList: [],
      currentCard: {},
      page: 1,
      pageSize: window.TUOTIAN_IS_MOBILE_FLAG ? 10 : 15,
      searchName: '',
    }
  }
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate() {
    const imgList = document.querySelectorAll('.card-img')
    imgList.forEach(el => {
      new Viewer(el);
    })
  }
  render() {
    const list = [], renderList = []
    const min = (this.state.page - 1) * this.state.pageSize
    const max = this.state.page * this.state.pageSize
    for (let i = min; i < max; i++) {
      if (!this.state.cardList[i]) {
        break
      }
      list.push(this.state.cardList[i])
    }
    list.forEach(card => {
      renderList.push(<div key={`card-${card.id}`} className="col-sm-4 col-xs-6 color-card-item"><img className="card-img" src={card.img} alt={card.name}/><p>{card.name}</p></div>)
    })
    return (
      <div className={window.TUOTIAN_IS_MOBILE_FLAG ? 'color-card mobile' : 'color-card'}>
        <div className="tuotian-bread">
          <div className="container">
            <div className="bread-title">
              <span className="zh-title">色卡中心</span>{window.TUOTIAN_IS_MOBILE_FLAG ? '' : 'PRODUCT COLOR'}
            </div>
            {
              !window.TUOTIAN_IS_MOBILE_FLAG ?
                <div className="bread-container">
                  <ol className="breadcrumb">
                    <li>首页</li>
                    <li>产品中心</li>
                    <li>色卡中心</li>
                  </ol>
                </div>
                : 
                this.state.typeId ? <div className="go-back" onClick={() => this.setState({typeId: 0})}>&lt;&lt;返回</div> : ''
            }
          </div>
        </div>
        <div className={window.TUOTIAN_IS_MOBILE_FLAG ? 'container color-card-main mobile' : 'container color-card-main'}>
          {
            !window.TUOTIAN_IS_MOBILE_FLAG || !this.state.typeId ?
            <Menu list={this.state.typeList} activeParent={this.state.activeParent} activeChild={this.state.activeChild} chooseType={this.chooseType.bind(this)} childKey="colorCardTypeChild" search={this.search.bind(this)}/>
            :
            ''
          }
          {
            this.state.typeId || !window.TUOTIAN_IS_MOBILE_FLAG ? 
              this.state.currentCard ? 
              <div className={window.TUOTIAN_IS_MOBILE_FLAG ? 'color-card-content mobile' : 'color-card-content'}>
                {
                  <div className="rendering-container">
                    {/* <img src={this.state.currentCard.renderingImg} alt={this.state.currentCard.name}/> */}
                    <img src="http://file.tuotianlvye.com/images/color/1558515303857_整体门窗.jpg" alt={this.state.currentCard.name}/>
                  </div>
                }
                <div className={window.TUOTIAN_IS_MOBILE_FLAG ? 'card-list-container mobile' : 'card-list-container'}>
                  <div className="row">
                    {renderList}
                  </div>
                </div>
                <Pager pageSize={this.state.pageSize} page={this.state.page} total={this.state.cardList.length} choosePage={this.choosePage.bind(this)}/>
              </div>
              :
              <div style={{lineHeight: '200px', textAlign: 'center', fontSize: '30px'}}>暂无相关信息</div>
            : ''
          }
        </div>
      </div>
    );
  }
  chooseType(parentId, childId) {
    const typeId = childId ? childId : parentId;
    this.props.history.push(`/colorcard?type=${typeId}`);
    window.$.get('/api/web/colorcard/v1/list', { typeId, status: 10, name: this.state.searchName }, (res) => {
      const data = res.data
      this.setState({typeId, activeParent: parentId, activeChild: childId, cardList: data, currentCard: data[0], page: 1})
    })
  }
  clickCard(card) {
    this.setState({currentCard: card})
  }
  choosePage(page) {
    this.setState({ page })
  }
  search(value) {
    this.setState({ searchName: value });
    this.getData(value);
  }
  getData(name = '') {
    window.$.get(`/api/web/colorcard/v1/tree?status=10&name=${name}`, (res) => {
      const arr = []
      const data = res.data
      let activeParent = 0, activeChild = 0
      data.forEach(item => {
        if (item.parentId === 0) {
          let pushFlag = false;
          if (item.colorCardList && item.colorCardList.length) {
            pushFlag = true
          } else if (item.colorCardTypeChild && item.colorCardTypeChild.length) {
            item.colorCardTypeChild.forEach(child => {
              
            })
            for (let i = item.colorCardTypeChild.length - 1; i >= 0; i -= 1) {
              const child = item.colorCardTypeChild[i];
              if (child.colorCardList && child.colorCardList.length) {
                pushFlag = true
              } else {
                item.colorCardTypeChild.splice(i, 1)
              }
            }
          }
          if (pushFlag) {
            arr.push(item)
            if (item.id === this.state.typeId) {
              activeParent = item.id
            } else if (item.colorCardTypeChild && item.colorCardTypeChild.length) {
              item.colorCardTypeChild.forEach(child => {
                if (child.id === this.state.typeId) {
                  activeParent = item.id
                  activeChild = child.id
                }
              })
            }
          }
        }
      });
      const typeId = this.state.typeId
      window.$.get('/api/web/colorcard/v1/list', { typeId, status: 10, name }, (res) => {
        const data = res.data
        this.setState({typeList: arr, activeParent, activeChild, cardList: data, currentCard: data[0]})
      })
      
    })
  }
}

export default withRouter(ColorCard)