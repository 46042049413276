import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Menu from '../components/Menu';
// import Pager from '../components/Pager';
import './page.css'
// import { getQueryString } from '../utils/utils'

class ColorCard extends Component {
  constructor(props) {
    super(props)
    // const typeId = Number(getQueryString('type'))
    this.state = {
      cityId: window.TUOTIAN_IS_MOBILE_FLAG ? 0 : 11,
      cityList: [],
      caseMap: {},
      // currentCard: {},
      // page: 1,
      // pageSize: window.TUOTIAN_IS_MOBILE_FLAG ? 10 : 15,
    }
  }
  componentDidMount() {
    this.getData();
  }
  render() {
    const list = this.state.caseMap[this.state.cityId], renderList = []
    if (list && Array.isArray(list)) {
      list.forEach(cCase => {
        renderList.push(<div key={`card-${cCase.id}`} className="col-sm-4 col-xs-6 color-card-item case"><img src={cCase.caseImg} alt={cCase.caseName}/><p>{cCase.caseName}</p></div>)
      })
    }
    return (
      <div className={window.TUOTIAN_IS_MOBILE_FLAG ? 'color-card mobile case' : 'color-card case'}>
        <div className="tuotian-bread">
          <div className="container">
            <div className="bread-title">
              <span className="zh-title">应用案例</span>{window.TUOTIAN_IS_MOBILE_FLAG ? '' : 'CASE'}
            </div>
            {
              !window.TUOTIAN_IS_MOBILE_FLAG ?
                <div className="bread-container">
                  <ol className="breadcrumb">
                    <li>首页</li>
                    <li>产品中心</li>
                    <li>应用案例</li>
                  </ol>
                </div>
                : 
                renderList.length ? <div className="go-back" onClick={() => this.setState({cityId: 0})}>&lt;&lt;返回</div> : ''
            }
          </div>
        </div>
        <div className={window.TUOTIAN_IS_MOBILE_FLAG ? 'container color-card-main mobile' : 'container color-card-main'}>
          {
            !window.TUOTIAN_IS_MOBILE_FLAG || !this.state.cityId ?
            <Menu list={this.state.cityList} activeParent={this.state.cityId} chooseType={this.chooseType.bind(this)} search={this.search.bind(this)}/>
            :
            ''
          }
          {
            this.state.cityId || !window.TUOTIAN_IS_MOBILE_FLAG ? 
              renderList.length ? 
              <div className={window.TUOTIAN_IS_MOBILE_FLAG ? 'color-card-content mobile' : 'color-card-content'}>
                <div className={window.TUOTIAN_IS_MOBILE_FLAG ? 'card-list-container mobile' : 'card-list-container'}>
                  <div className="row">
                    {renderList}
                  </div>
                </div>
                {/* <Pager pageSize={this.state.pageSize} page={this.state.page} total={this.state.cardList.length} choosePage={this.choosePage.bind(this)}/> */}
              </div>
              :
              <div style={{lineHeight: '200px', textAlign: 'center', fontSize: '30px'}}>暂无相关信息</div>
            : ''
          }
        </div>
      </div>
    );
  }
  chooseType(parentId) {
    this.setState({ cityId: parentId });
  }
  search(value) {
    this.getData(value);
  }
  getData(name = '') {
    window.$.get(`/api/web/citycase/v1/list?status=10&name=${name}`, (res) => {
      const data = res.data
      const list = []
      Object.keys(data).forEach(key => {
        const temp = {
          id: Number(key),
          name: data[key][0].cityName,
        }
        list.push(temp)
      })
      this.setState({ caseMap: data, cityList: list })
    })
  }
  // clickCard(card) {
  //   this.setState({currentCard: card})
  // }
  // choosePage(page) {
  //   this.setState({ page })
  // }
}

export default withRouter(ColorCard)