export function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null)
      return decodeURI(r[2]);
  return null;
}

export function isMobile(navigator) {
  if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    return true
  }
  return false
}

export function isWechat(navigator) {
  if(/MicroMessenger/i.test(navigator.userAgent)) {
    return true
  }
  return false
}

export function isAndroid(navigator) {
  if(/Android/i.test(navigator.userAgent)) {
    return true
  }
  return false
}
