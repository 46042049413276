import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

let language = 'zh'
if (document.domain === 'en.tuotianlvye.com') {
  language = 'en'
}

ReactDOM.render(<App language={language}/>, document.getElementById('root'));
var timer = setInterval(function(){
  if (window && window.$ && window.$('.carousel') && window.$('.carousel').carousel) {
    window.$('.carousel').carousel({
      interval: 2500
    })
    clearInterval(timer)
    timer = null
  }
}, 50)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
