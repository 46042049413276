import React, { Component } from 'react';
// import {DownloadOutlined} from '@ant-design/icons';
import './page.css'

export default class Notice extends Component {
  render() {
    return (
      <div>
        <div className="container">
          <div className="notice-title">2024年危险废物污染环境防治信息公开</div>
          {/* <div className="notice-time">2022-07-11 11:12:11&nbsp;&nbsp;来源:杜尔伯特政府网</div> */}
          {/* <div className="notice-sub-title">一、建设项目名称及概要</div> */}
          <p className="notice-content">依据《中华人民共和国固体废物污染环境防治法》第二十九条规定：“产生、收集、贮存、运输、利用、处置固体废物的单位，应当依法及时公开固体废物污染环境防止信息，主动接受社会监督。”</p>
          <p className="notice-content">现将已提交2023年危险废物污染环境防止信息的企业名单及产废信息公开如下，接受公众监督。</p>
          {/* <p className="notice-content">建设地点：辽宁省营口市旗口镇红东村</p>
          <p className="notice-content">建设内容：包括 6 条熔铸生产线、28 条挤压生产线、4 条喷涂生产线、2 条电泳生产线、1 条氧化生产线、2 条氟碳喷涂线、3 条喷砂线、4 条拉丝线、2 条抛光线、5 条粉末涂料线、55 条隔热条生产线、12000 套/a 模具生产线、9 条发泡填充生产线以及 1 台 4t/h 天然气锅炉、污水处理站等公辅工程。</p>
          <div className="notice-sub-title">二、建设单位名称和联系方式</div>
          <p className="notice-content">建设单位：辽宁托田铝业有限公司</p>
          <p className="notice-content">联 系 人：曹先生</p>
          <p className="notice-content">联系方式：13591904060</p>
          <p className="notice-content">通讯地址：辽宁省营口市旗口镇红东村</p>
          <p className="notice-content">电子邮箱: 174037046@qq.com</p>
          <div className="notice-sub-title">三、环境影响报告书编制单位的名称</div>
          <p className="notice-content">环评单位：中冶焦耐（大连）工程技术有限公司</p>
          <p className="notice-content">联系地址：辽宁省大连市高新园区高能街 128 号</p>
          <p className="notice-content">联系人：孙女士</p>
          <p className="notice-content">邮编：116085</p>
          <p className="notice-content">联系电话：0411-82461096</p>
          <p className="notice-content">电子信箱：250379678@qq.com</p>
          <div className="notice-sub-title">四、公众意见表的网络链接</div>
          <p className="notice-content">公众意见表的网络链接为：链接：</p>
          <p className="notice-content">https://pan.baidu.com/s/1h1a-E2EYHFlyOgbj9MT3wA?pwd=scfg 提取码：scfg</p>
          <div className="notice-sub-title">五、环境影响报告书征求意见稿全文网络链接及查阅纸质报告书的方式和途径</div>
          <p className="notice-content">环境影响报告书征求意见稿全文网络链接：</p>
          <p className="notice-content">https://pan.baidu.com/s/1HIQXhX_Wu1OFQm91SiTyPA?pwd=wp0f</p>
          <p className="notice-content">提取码：wp0f</p>
          <p className="notice-content">查阅纸质报告书的方式和途径：请发邮件至 250379678@qq.com 申请调阅</p>
          <div className="notice-sub-title">六、征求意见的公众范围及公众提出意见的方式和途径</div>
          <p className="notice-content">征求意见公众范围为红东村、红西村等可能受影响的公众。</p>
          <p className="notice-content">公众可通过信函、传真、电子邮件等方式，在规定时间内将填写的公众意见表提交建设单位。公众提交意见时，请提供有效的联系方式。</p>
          <div className="notice-sub-title">七、公众提出意见的起止时间</div>
          <p className="notice-content">本公告发布之日起 10 个工作日内。</p>
          <div className="notice-sub-title" style={{ textAlign: 'right', marginBottom: '20px' }}>辽宁托田铝业有限公司</div> */}
          <div className="notice-fujian">
            <span>附件</span>
            <a href="/file/2024年危险废物污染环境防治信息公开.pdf" style={{ bottom: '40px' }}>2024年危险废物污染环境防治信息公开.pdf(3MB)</a>
            <a href="/file/辽宁托田铝业有限公司清洁生产信息公示.pdf">辽宁托田铝业有限公司清洁生产信息公示.pdf(763KB)</a>
          </div>
        </div>
      </div>
    );
  }
}