import React, { Component } from 'react';
import './page.css'

export default class Home extends Component {
  render() {
    return (
      <div className="container-fluid home">
        <div className="center-logo"><img src="/images/center_logo.png" alt="托田断桥"/></div>
        <div className={window.TUOTIAN_IS_MOBILE_FLAG ? 'container home-about mobile' : 'container home-about'}>
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12 home-about-text">
              <div className="home-about-text-title">
                <p className="en-title">About us</p>
                <p className="zh-title">托田铝业有限公司</p>
              </div>
              <div className="home-about-text-content">
                <p>辽宁托田铝业有限公司，是集铝合金型材产品研发、全套生产与销售为一体，并具有自主生产热挤压模具、粉末涂料、隔热条、包装材料等能力的综合型企业。</p>
                <p>年产能达到10万吨，产品共有800多个系列，20000多个型号规格。已获各项专利120多项，产品已服务1000多万户家庭，特别是隔热型材在国内享誉胜名。是国内少有的产业配套齐全的大型企业。</p>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12 home-about-img">
              <img src="/images/home_5.jpg" alt="托田断桥"/>
            </div>
          </div>
        </div>
        {
          window.TUOTIAN_IS_MOBILE_FLAG ? 
          ''
          :
          <div className="home-product-list">
            <a href="/product-list?type=1" className="home-product"><img src="/images/home_product_1.jpg" alt="托田断桥"/>
              <span className="mask">
                <p className="home-product-type">托田断桥</p>
                <p className="see-more"><span>查看更多</span></p>
              </span>
            </a>
            <a href="/product-list?type=2" className="home-product"><img src="/images/home_product_2.jpg" alt="肯德基门"/>
              <span className="mask">
                <p className="home-product-type">肯德基门</p>
                <p className="see-more"><span>查看更多</span></p>
              </span>
            </a>
            <a href="/product-list?type=3" className="home-product"><img src="/images/home_product_3.jpg" alt="普铝门窗"/>
              <span className="mask">
                <p className="home-product-type">普铝门窗</p>
                <p className="see-more"><span>查看更多</span></p>
              </span>
            </a>
            <a href="/product-list?type=6" className="home-product" style={{width: '14.32%'}}><img src="/images/home_product_4.jpg" alt="幕墙型材"/>
              <span className="mask">
                <p className="home-product-type">幕墙型材</p>
                <p className="see-more"><span>查看更多</span></p>
              </span>
            </a>
            <a href="/product-list?type=4" className="home-product"><img src="/images/home_product_5.jpg" alt="阳光房型材"/>
              <span className="mask">
                <p className="home-product-type">阳光房型材</p>
                <p className="see-more"><span>查看更多</span></p>
              </span>
            </a>
            <a href="/product-list?type=5" className="home-product"><img src="/images/home_product_6.jpg" alt="家具移门"/>
              <span className="mask">
                <p className="home-product-type">家具移门</p>
                <p className="see-more"><span>查看更多</span></p>
              </span>
            </a>
            <a href="/product-list?type=7" className="home-product"><img src="/images/home_product_7.jpg" alt="工业型材"/>
              <span className="mask">
                <p className="home-product-type">工业型材</p>
                <p className="see-more"><span>查看更多</span></p>
              </span>
            </a>
          </div>
        }
        <div className="row home-imgs">
          <div className="col-md-3 col-sm-6 col-xs-6">
            <img src="/images/home_1.jpg" alt="托田断桥"/>
            <p className="home-title">隔热防暑</p>
            <p className="home-desc">让家更清凉</p>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-6">
            <img src="/images/home_2.jpg" alt="托田断桥"/>
            <p className="home-title">隔冷防寒</p>
            <p className="home-desc">让家更温暖</p>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-6">
            <img src="/images/home_3.jpg" alt="托田断桥"/>
            <p className="home-title">隔音防噪</p>
            <p className="home-desc">让家更安静</p>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-6">
            <img src="/images/home_4.jpg" alt="托田断桥"/>
            <p className="home-title">隔雨防风</p>
            <p className="home-desc">让家更安心</p>
          </div>
        </div>
        {
          window.TUOTIAN_IS_MOBILE_FLAG ? 
          <div className="mobile-pinpai container">
            <img src="/images/pinpai_6.png" alt="品牌墙"/>
            <img src="/images/pinpai_7.png" alt="品牌墙"/>
            <img src="/images/pinpai_8.png" alt="品牌墙"/>
            <img src="/images/pinpai_1.png" alt="品牌墙"/>
            <img src="/images/pinpai_2.png" alt="品牌墙"/>
            <img src="/images/pinpai_3.png" alt="品牌墙"/>
            <img src="/images/pinpai_4.png" alt="品牌墙"/>
            <img src="/images/pinpai_5.png" alt="品牌墙"/>
            <img src="/images/pinpai_9.png" alt="品牌墙"/>
          </div>
          :
          <div>
            <div className="pinpai container-fluid">
              <img src="/images/pinpai_6.png" alt="品牌墙"/>
              <img src="/images/pinpai_7.png" alt="品牌墙"/>
              <img src="/images/pinpai_8.png" alt="品牌墙"/>
            </div>
            <div className="pinpai container-fluid">
              <img src="/images/pinpai_1.png" alt="品牌墙"/>
              <img src="/images/pinpai_2.png" alt="品牌墙"/>
              <img src="/images/pinpai_3.png" alt="品牌墙"/>
            </div>
            <div className="pinpai container-fluid">
              <img src="/images/pinpai_4.png" alt="品牌墙"/>
              <img src="/images/pinpai_5.png" alt="品牌墙"/>
              <img src="/images/pinpai_9.png" alt="品牌墙"/>
            </div>
          </div>
        }
      </div>
    );
  }
}