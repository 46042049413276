import React, { Component } from 'react';
import './page.css'

export default class Product extends Component {
  render() {
    return (
      <div className={window.TUOTIAN_IS_MOBILE_FLAG ? 'product mobile' : 'product'}>
        <div className="product-title">产品中心</div>
        <div className="separator"><span></span></div>
        {
          window.TUOTIAN_IS_MOBILE_FLAG ? 
          <div className="mobile-product-type">
            <div className="row">
              <div className="col-xs-6">
                <a href="/product-list?type=1" className="tuotian">托田断桥</a>
              </div>
              <div className="col-xs-6">
                <a href="/colorcard" className="colorcard">色卡中心</a>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-4">
                <a href="/product-list?type=2">肯德基门</a>
              </div>
              <div className="col-xs-4">
                <a href="/product-list?type=3">普铝门窗</a>
              </div>
              <div className="col-xs-4">
                <a href="/product-list?type=6">幕墙型材</a>
              </div>
              <div className="col-xs-4">
                <a href="/product-list?type=4">阳光房型材</a>
              </div>
              <div className="col-xs-4">
                <a href="/product-list?type=5">家具移门</a>
              </div>
              <div className="col-xs-4">
                <a href="/product-list?type=7">工业型材</a>
              </div>
            </div>
          </div>
          :
          <div className="duanqiao-and-seka">
            <div className="row">
              <div className="col-sm-6 col-xs-12">
                <a href="/product-list?type=1"><img src="/images/product_1.jpg" alt="托田断桥"/></a>
              </div>
              <div className="col-sm-6 col-xs-12">
                <a href="/colorcard?type=31"><img src="/images/product_2.jpg" alt="色卡中心"/></a>
              </div>
            </div>
          </div>
        }
        {
          window.TUOTIAN_IS_MOBILE_FLAG ? '' :
          <div className="product-type">
            <div className="row">
              <div className="col-sm-2">
                <a href="/product-list?type=2"><img src="/images/product_3.jpg" alt="肯德基门"/></a>
              </div>
              <div className="col-sm-2">
                <a href="/product-list?type=3"><img src="/images/product_4.jpg" alt="普铝门窗"/></a>
              </div>
              <div className="col-sm-2">
                <a href="/product-list?type=6"><img src="/images/product_5.jpg" alt="幕墙型材"/></a>
              </div>
              <div className="col-sm-2">
                <a href="/product-list?type=4"><img src="/images/product_6.jpg" alt="阳光房型材"/></a>
              </div>
              <div className="col-sm-2">
                <a href="/product-list?type=5"><img src="/images/product_7.jpg" alt="家具移门"/></a>
              </div>
              <div className="col-sm-2">
                <a href="/product-list?type=7"><img src="/images/product_8.jpg" alt="工业型材"/></a>
              </div>
            </div>
          </div>
        }
        {/* <div style={{textAlign: 'center', marginTop: '80px'}}><span className="top-border-title">新品展厅</span></div>
        <div className="separator"><span></span></div>
        <div className="container new-product">
          <div className="row">
            <div className="col-sm-4 col-xs-12">
              <a href="/product-list?type=1"><img src="/images/product_3.jpg" alt="托田断桥"/></a>
            </div>
            <div className="col-sm-4 col-xs-12">
              <a href="/product-list?type=1"><img src="/images/product_3.jpg" alt="托田断桥"/></a>
            </div>
            <div className="col-sm-4 col-xs-12">
              <a href="/product-list?type=1"><img src="/images/product_3.jpg" alt="托田断桥"/></a>
            </div>
          </div>
        </div> */}
        <div className="product-example">应用案例</div>
        <div className="separator"><span></span></div>
        <div className="product-example-container">
          <div className="row">
            <div className="col-sm-4 col-xs-12 example">
              <div className="example-position-helper">
                <img src="/images/product_12.jpg" alt="北京顶秀美泉"/>
                <span className="example-text">
                  <span className="text-position-helper">
                    <span className="example-name">北京顶秀美泉</span>
                    <span className="example-line"></span>
                  </span>
                </span>
              </div>
            </div>
            <div className="col-sm-4 col-xs-12 example">
              <div className="example-position-helper">
                <img src="/images/product_13.jpg" alt="北京华侨城"/>
                <span className="example-text">
                  <span className="text-position-helper">
                    <span className="example-name">北京华侨城</span>
                    <span className="example-line"></span>
                  </span>
                </span>
              </div>
            </div>
            <div className="col-sm-4 col-xs-12 example">
              <a className="fff" href="/case">
                <img src="/images/product_more.png" alt="更多"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}